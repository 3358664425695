let axios = require('axios')

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 60000
})

// request拦截器
service.interceptors.request.use(config => {
  if (window.xCtx) {
    // 自定义头部
    config.headers['x-ctx'] = window.xCtx
  }
  if (window.qrcode) {
    // 自定义头部
    config.headers['qrcode'] = window.qrcode
  }
  return config
}, error => {
  console.log(error) //   for debug
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
    const resp = response.data;
    switch (resp.code) {
      case 0:
      case 200:
      case '0000':
        return response.data;
      default:
        return Promise.reject(resp);
    }
  },
  error => {
    return Promise.reject(error)
  }
)

// export default service
export default {
  post(url, data, setObj) {
    return new Promise((resolve, reject) => {
      service.post(
        url,
        data,
        setObj
      ).then(function (resp) {
        resolve(resp)
      }).catch(function (error) {
        reject(error);
      })
    })
  },
  get(url, data) {
    return new Promise((resolve, reject) => {
      service.get(
        url,
        data
      ).then(function (resp) {
        resolve(resp)
      }).catch(function (error) {
        reject(error);
      })
    })
  }
}
