<template>
  <div class="tips-wrapper show-tips">
    <div class="tips-main">
      <slot></slot>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
  export default {}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
  .tips-wrapper
    position fixed
    top 0
    left 0
    display flex
    align-items center
    justify-content center
    opacity 0
    width 100%
    height 100%
    background transparent
    perspective 1000
    transform translate3d(0, 0, 0)
    transition opacity .5s ease-in-out .5s
    z-index 99999
    .tips-main
      font-size .14583rem
      color #fff
      max-width 80%
      line-height 1.4
      padding 15px 20px
      border-radius 6px
      background rgba(0,0,0,.6)
    &.show-tips
      opacity 1
</style>
