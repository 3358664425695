<template>
  <div class="dairy-wrapper">
    <p-header></p-header>
    <div class="banner">
      <img src="~common/images/service_bg.png" width="100%" alt="">
    </div>
    <div class="form-container">
      <div class="title">欢迎了解稿真无忧<div style="font-size: 0.09rem;display: inline;vertical-align: super;">®</div>服务平台</div>
      <div class="subtitle">请填写以下资料，获取更多产品信息</div>
      <div class="input-box">
        <div class="input-title">您的姓名</div>
        <input class="input-input" maxlength="20" v-model.trim="userName" type="text" placeholder="请输入您的姓名" />
      </div>
      <div class="input-box">
        <div class="input-title">联系电话</div>
        <input class="input-input" maxlength="11" v-model.trim="phonenumber" type="text" placeholder="请输入联系电话" />
      </div>
      <div class="input-box">
        <div class="input-title">公司名称</div>
        <input class="input-input" maxlength="20" v-model.trim="companyName" type="text" placeholder="请输入公司名称" />
      </div>
      <div class="input-box">
        <div class="input-title">您的职位</div>
        <input class="input-input" maxlength="20" v-model.trim="position" type="text" placeholder="请输入您的职位" />
      </div>
      <div class="chk-box">
        <input class="input-input" v-model="isAgree" type="checkbox" />
        <div style="margin-left: 5px;    transform: scale(0.7);white-space: nowrap;transform-origin: left;">我已阅读了解并同意<a @click="tapPrivacyAgreement" style="cursor:pointer">《隐私条款》</a>及<a @click="tapUserAgreement" style="cursor:pointer">《服务条款》</a>等表述。</div>
      </div>
      <div class="btn-box">
        <img src="~common/images/service_btn2.png" @click="submitData" style="width:0.69rem" alt="" />
        <a href="http://saas.gzwy.cc" target="blank"><img src="~common/images/service_btn1.png" style="width:0.69rem;margin-left: 0.21rem" alt="" /></a>
      </div>
      <div class="btn-box" v-show="submitSuccess" >申请成功，2个工作日内会有专属客户经理联系您</div>
    </div>
    <tips v-show="msg !== false">
      <p v-html="msg"></p>
    </tips>
    <p-footer></p-footer>
    
  </div>
</template>

<script type="text/ecmascript-6">
  import api from '@/api/api'
  import PHeader from 'components/header/header'
  import PFooter from 'components/footer/footer'
  import Tips from 'components/tips/tips'

  export default {
    data() {
      return {
        submitDataFlag: true,
        submitSuccess: false,
        userName: "",
        phonenumber: "",
        companyName: "",
        position: "",
        isAgree: false,
        msg: false
      }
    },
    created() {
    },
    activated() {
    },
    methods: {
      submitData() {
        if(!this.userName) {
          this.msg = '请输入您的姓名'
          return false;
        }
        if(!this.phonenumber) {
          this.msg = '请输入联系电话'
          return false;
        }
        if(!this.companyName) {
          this.msg = '请输入公司名称'
          return false;
        }
        if(!this.position) {
          this.msg = '请输入您的职位'
          return false;
        }
        if(!this.checkPhone(this.phonenumber)) {
          this.msg = '请输入正确的联系电话'
          return false;
        }
        if(!this.isAgree) {
          this.msg = '请阅读并同意平台协议'
          return false;
        }
        if (this.submitDataFlag) {
          this.submitDataFlag = false
          api.submitData({
            userName: this.userName,
            phonenumber: this.phonenumber,
            companyName: this.companyName,
            position: this.position,
            isAgree: 0,
          }).then((res) => {
            this.submitDataFlag = true
            this.submitSuccess = true
            this.msg = '申请成功';
            this.userName = "";
            this.phonenumber = "";
            this.companyName = "";
            this.position = "";
            this.isAgree = false;
            // setTimeout(() => {
            //   window.location.reload()
            // }, 1500)
          }).catch((err) => {
            this.submitDataFlag = true
            if (err.message) {
              this.msg = err.message
            }
          })
        }
      },
      checkPhone(val) {
        let reExg = /0?(11|12|13|14|15|16|17|18|19)[0-9]{9}/
        return reExg.test(val)
      },
      goDetail(id) {
        this.$router.push({
          name: 'caseDetail', params: {
            id: id,
            type: 0
          }
        })
      },
      tapPrivacyAgreement() {
        this.$router.push({
          name: 'privacyAgreement'
        })
      },
      tapUserAgreement() {
        this.$router.push({
          name: 'userAgreement'
        })
      }
    },
    watch: {
      msg(val) {
        if (val || val === '') {
          setTimeout(() => {
            this.msg = false
          }, 2000)
        }
      }
    },
    components: {
      PHeader,
      PFooter,
      Tips
    }
  }
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
  .dairy-wrapper
    width 100%
    min-width 1270px
    .banner
      padding-top: 0.35rem;
      position relative
      text-align: center
      width: 100%
    .form-container
      position absolute
      top 0.8rem
      right: 1rem;
      width 2.67rem
      height: 3rem
      background: #FFFFFF;
      border-radius: 20px;
      box-sizing border-box
      .title
        font-size: 0.14rem;
        font-weight: bold;
        color: #000000;
        line-height: 0.16rem;
        text-align: center;
        margin-top: 0.22rem
      .subtitle
        font-size: 0.07rem;
        font-weight: 300;
        color: #666666;
        line-height: 0.16rem;
        text-align: center;
        margin-top: 0.07rem
      .input-box
        display flex
        padding 0 0.28rem 0 0.33rem
        margin-top: 0.11rem
        .input-title
          font-size: 0.08rem;
          font-weight: bold;
          line-height: 0.2rem
          color: #000000;
          margin-right: 0.1rem
        .input-input
          flex: 1
          font-size: 0.08rem;
          padding-left: 0.05rem
          height: 0.2rem;
          border: 1px solid #dddddd
          border-radius: 5px
      .btn-box
        text-align: center
        font-size: 0.07rem;
        font-weight: 300;
        color: #666666;
        line-height: 0.16rem;
        margin-top: 0.14rem
      .chk-box
        margin-top: 0.14rem
        font-size: 10px;
        font-weight: 300;
        display: flex;
        color: #666666;
        padding-left: 0.74rem
</style>
